<template>
  <div :class="{ loader: true, fadeout: !isLoading }" class="justify-content-center d-flex flex-wrap">
    <!-- <b-img alt="Vue logo" :src="require('@/assets/loading.gif')" class="align-self-center img-loader"></b-img> -->
    <div id="content">
        <div class="mainHolder">Initialize<span class="dot one">.</span><span class="dot two">.</span><span class="dot three">.</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: ["isLoading"]
};
</script>

<style>
.loader {
  background-color: #000000;
  bottom: 0;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  /* padding-top: 10vh; */
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 2000;
}
.mainHolder{
    color: #fff;
    font-size:20px;
    width: 200px;
    position: absolute;
    margin: 0;
    position: absolute;
    bottom:10px;
    left: 3px;
}
.dot {
    display: inline;
    position: relative;
    opacity: 0;
    padding:2px;
    animation: showHideDot 2.5s ease-in-out infinite;
  }
.one { animation-delay: 0.2s; }
.two { animation-delay: 0.4s; }
.three { animation-delay: 0.6s; }
@keyframes showHideDot {
    0% { opacity: 0; }
    50% { opacity: 1; }
    60% { opacity: 1; }
    100% { opacity: 0; }
}
.img-load {
    width: 40%;
    height: 40%;
}

.img-loader {
  width: 50px;
}

.fadeout {
  animation: fadeout 0.3s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
