import { render, staticRenderFns } from "./Syarat.vue?vue&type=template&id=db7c4d4e&scoped=true&"
import script from "./Syarat.vue?vue&type=script&lang=js&"
export * from "./Syarat.vue?vue&type=script&lang=js&"
import style0 from "./Syarat.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Syarat.vue?vue&type=style&index=1&id=db7c4d4e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db7c4d4e",
  null
  
)

export default component.exports